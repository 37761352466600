<template>
	<div class="flex h-full w-full py-4">
		<div class="flex flex-row justify-between h-full w-full px-4 space-x-4">
			<div class="flex flex-col h-full w-1/5">
				<div class="bg-white shadow sm:rounded-lg sm:overflow-hidden h-full">
					<div class="divide-y divide-gray-200">
						<div class="px-4 pt-4 pb-3 sm:px-6">
							<h2 id="responses-title" class="text-lg font-medium text-gray-900">Ticket Workflows</h2>
						</div>
					</div>
				</div>
			</div>
			<div class="flex flex-col h-full w-4/5">
				<WorkflowBuilder :allow-edit="false" :function-mode="true" :workflow-id="activityStore.activity.workflow_id ?? ''" :workflow_revision_id="activityStore.activity.workflow_revision_id" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import WorkflowBuilder from '@modules/workflow/components/WorkflowBuilder.vue';
	import { useActivityStore } from '@modules/activities/store';

	const activityStore = useActivityStore();
</script>
